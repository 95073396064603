import React, { useState, useEffect } from "react";
import { Container, Navbar, Nav, Button, Row, Col, Card, Form } from "react-bootstrap";
import { Envelope, ArrowUp } from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import heroImage from "./assets/hero.jpg";
import servicesImage from "./assets/services.jpg";
import wpImage from "./assets/wp.jpg";
import androidImage from "./assets/android.jpg";
import aboutImage from "./assets/about.jpg";

const LandingPage = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (window.scrollY > 300) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://proces.hr" />
      </Helmet>
      {/* Navbar */}
      <Navbar bg="primary" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="#">Proces d.o.o.</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#services">Usluge</Nav.Link>
              <Nav.Link href="https://proces.screenconnect.com" target="_blank" rel="noopener noreferrer">
                Udaljena podrška
              </Nav.Link>
              <Nav.Link href="#about">O nama</Nav.Link>
              <Nav.Link href="#contact" className="d-flex align-items-center">
                <Envelope className="me-1" size={16} />
                Kontakt
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Hero Section */}
      <Container className="text-center my-5">
        <h1 className="text-primary">Inovativna IT Rješenja za Vaše Poslovanje</h1>
        <p className="text-secondary">Proces d.o.o. - za digitalne usluge</p>
        <img src={heroImage} className="img-fluid rounded" alt="IT Solutions" />
        <div className="mt-3">
          <Button
            variant="primary"
            href="#contact"
            className="w-auto mx-auto d-inline-flex align-items-center"
          >
            <Envelope className="me-2" size={18} />
            Kontaktirajte nas
          </Button>
        </div>
      </Container>

      {/* Services Section */}
      <Container id="services" className="my-5">
        <h2 className="text-center text-primary">Naše Usluge</h2>
        <Row>
          <Col md={4}>
            <Card className="my-3 shadow">
              <Card.Img variant="top" src={wpImage} />
              <Card.Body>
                <Card.Title>WordPress Integracije</Card.Title>
                <Card.Text>Razvijamo prilagođene funkcionalnosti i API poveznice.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="my-3 shadow">
              <Card.Img variant="top" src={androidImage} />
              <Card.Body>
                <Card.Title>Android Razvoj</Card.Title>
                <Card.Text>Specijalizacija za push notifikacije i kalendarske aplikacije.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="my-3 shadow">
              <Card.Img variant="top" src={servicesImage} />
              <Card.Body>
                <Card.Title>Knjigovodstveni Softver</Card.Title>
                <Card.Text>Offline rješenja uz TopSpeed baze podataka.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* About Section */}
      <Container id="about" className="my-5 text-center">
        <h2 className="text-primary">O nama</h2>
        <p className="text-secondary">
          <strong>O NAMA - PROCES D.O.O.</strong><br />
          Specijalizirani IT partner za digitalnu transformaciju poslovanja.
        </p>
        <h4 className="text-primary mt-4">Djelatnosti</h4>
        <ul className="text-secondary text-start mx-auto" style={{ maxWidth: "600px" }}>
          <li>Prilagođeni softverski razvoj (web i mobilne aplikacije)</li>
          <li>WordPress integracije s vanjskim sustavima i API-jevima</li>
          <li>Razvoj Android aplikacija s fokusom na push notifikacije i kalendarske funkcionalnosti</li>
          <li>Poslovni softver (knjigovodstveni sustavi bazirani na TopSpeed tehnologiji)</li>
        </ul>
        <h4 className="text-primary mt-4">Naše prednosti</h4>
        <ul className="text-secondary text-start mx-auto" style={{ maxWidth: "600px" }}>
          <li><strong>Specijalizacija</strong> – Duboko poznavanje WordPressa, Android platforme i TopSpeed baza</li>
          <li><strong>Fleksibilnost</strong> – Rješenja "šivena po mjeri" umjesto generičkih paketa</li>
          <li><strong>Lokalna podrška</strong> – Brz odaziv i personalizirani pristup</li>
        </ul>
      </Container>

      {/* Kontakt */}
      <Container id="contact" className="my-5 text-center">
        <h2 className="text-primary">Kontaktirajte nas</h2>
        <Button variant="primary" href="mailto:proces@proces.hr">Pošaljite Email</Button>
      </Container>

      {/* Strelica za povratak na vrh */}
      <div
        onClick={scrollToTop}
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          cursor: "pointer",
          display: showScroll ? "block" : "none",
          backgroundColor: "#007bff",
          color: "white",
          borderRadius: "50%",
          padding: "10px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <ArrowUp size={24} />
      </div>
    </>
  );
};

export default LandingPage;
